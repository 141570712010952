import React from 'react'
import { graphql, PageProps } from 'gatsby'
import get from 'lodash/get'
import Seo from '../components/seo'
import Navigation from '../components/NavBar'
import { BlogPostBySlugQuery } from '../generated/graphql'
import { ErrorComponent } from '../components/Error'
// @ts-ignore
import BgImage from '../blog-bg.png'

const BlogPostTemplate = ({
  data,
  location,
  pageContext: { locale },
}: PageProps<
  BlogPostBySlugQuery,
  {
    slug: string
    previousPostSlug: string
    nextPostSlug: string
    locale: string
  }
>) => {
  const post = get(data, 'contentfulBlogPost')
  if (!post) {
    return <ErrorComponent message="Unable to load blog" />
  }

  return (
    <div
      className="h-screen relative bg-[#f5f5f5] bg-cover"
      style={{ backgroundImage: `url(${BgImage})` }}
    >
      <Seo title={`Puzzle Ventures - Writing`} />
      <main className="mx-auto overflow-hidden h-full relative flex flex-col container max-w-6xl">
        <Navigation location={location} dark locale={locale} />
        <div className="flex overflow-y-auto flex-col flex-auto pb-8 w-full pt-16 px-6">
          <div className="max-w-4xl text-bg">
            <h1>{post.title}</h1>
            <div className="flex mb-12">
              <p>BY {post.author?.name}</p>
              <p className="ml-8">
                {post.publishDate} |{' '}
                {post.readingTime ? `${post.readingTime} min` : ''}
              </p>
            </div>
            <div
              className="blog-post"
              dangerouslySetInnerHTML={{
                __html: post.body?.childMarkdownRemark?.html!,
              }}
            />
          </div>
        </div>
      </main>
    </div>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      slug
      createdAt(formatString: "MMM Do, YYYY")
      author {
        name
      }
      readingTime
      publishDate(formatString: "MMMM Do, YYYY")
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`
